import React, { useEffect, useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Layout, Loader } from "../../components";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { StyledViewAssignment } from "./ViewAssignment.styles";
import * as XLSX from "xlsx";

import { RootReducer } from "src/redux/reducers";
import { getAssignmentStatusActions, getAssignmentUserActivityActions } from "src/redux/actions/assignments";

const columnHelper = createColumnHelper<any>();

const ViewAssignment = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [studentsData, setStudentsData] = useState<any>([]);
  const [activityData, setActivityData] = useState<any>([]);

  const {
    assignments: { assignmentStatus, assignmentUserActivity }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const getAssignmentStatus = useCallback(
    (id: number) =>
      dispatch(
        getAssignmentStatusActions.request({
          id: id
        })
      ),
    [dispatch]
  );

  const viewStudentActivityHandler = (userId: number) => {
    dispatch(
      getAssignmentUserActivityActions.request({
        id: Math.abs(parseInt(id || "")),
        userId: userId
      })
    );
  };

  const viewActivityItemHandler = (studentId: number, challengeId: number) => {
    window.location.href = `/progress-report-students/${studentId}/assignments/quiz/${challengeId}`;
  };

  useEffect(() => {
    setStudentsData(assignmentStatus);
    setIsLoading(false);
  }, [assignmentStatus]);

  useEffect(() => {
    setActivityData(assignmentUserActivity);
  }, [assignmentUserActivity]);

  const columnsStudents = [
    columnHelper.accessor("groupName", {
      header: () => t("assignments_group_name"),
      cell: (info) => info.getValue() ?? "-"
    }),
    columnHelper.accessor("name", {
      header: () => t("assignments_student_name"),
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor("bookCompletedInAssignment", {
      header: () => t("assignments_books_completed"),
      cell: (info) => `${info.getValue()} / ${info.row.original.totalBooksInAssigment}`
    }),
    columnHelper.accessor("activitiesCompletedInAssigment", {
      header: () => t("assignments_activities_completed"),
      cell: (info) => `${info.getValue()} / ${info.row.original.totalActivitiesInAssigment}`
    }),
    columnHelper.accessor("lastActivityDate", {
      cell: (info) => moment(info.getValue()).format("YYYY-MM-DD"),
      header: () => t("assignments_last_activity")
    }),
    columnHelper.display({
      id: "actions",
      header: `${t("assignments_table_cell_actions")}`,
      cell: ({ cell }) => {
        return (
          <button
            type="button"
            className="btn btn-error btn-edit"
            onClick={() => viewStudentActivityHandler(cell.row.original.userId)}
          >
            {t("assignments_button_view")}
          </button>
        );
      },
      size: 2
    })
  ];

  const columnsActivity = [
    columnHelper.accessor("activityDate", {
      cell: (info) => moment(info.getValue()).format("YYYY-MM-DD"),
      header: () => t("assignments_table_cell_date")
    }),
    columnHelper.accessor("name", {
      header: () => t("assignments_table_cell_activity"),
      cell: (info) => {
        if (info.row.original.activity === 1) {
          return "Reading";
        } else {
          return "Quiz";
        }
      }
    }),
    columnHelper.accessor("correctAnswers", {
      header: () => t("assignments_table_cell_correct_answers"),
      cell: (info) => {
        if (info.row.original.numberOfQuestions && info.row.original.numberOfQuestions > 0) {
          const correctAnswers = info.row.original.correctAnswers;
          const totalQuestions = info.row.original.numberOfQuestions;
          return `${correctAnswers} out of ${totalQuestions}`;
        } else {
          const progress = info.row.original.progressInPercent;
          return `${progress}%`;
        }
      }
    }),
    columnHelper.accessor("bookName", {
      header: () => t("assignments_table_cell_book_name"),
      cell: (info) => info.getValue()
    }),

    columnHelper.display({
      id: "actions",
      header: `${t("assignments_table_cell_actions")}`,
      cell: (info) => {
        const [t] = useTranslation();
        return (
          <button
            type="button"
            className="btn btn-error btn-edit"
            onClick={() => viewActivityItemHandler(info.row.original.userId, info.row.original.challengeId)}
          >
            {t("assignments_button_view")}
          </button>
        );
      },
      size: 2
    })
  ];

  const tableStudents = useReactTable({
    data: studentsData,
    columns: columnsStudents,
    getCoreRowModel: getCoreRowModel()
  });

  const tableActivity = useReactTable({
    data: activityData,
    columns: columnsActivity,
    getCoreRowModel: getCoreRowModel()
  });

  const acivitySectionRef = useRef<HTMLDivElement | null>(null);

  const scrollToActivity = () => {
    if (acivitySectionRef.current) {
      acivitySectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(scrollToActivity, [activityData]);

  //for test
  useEffect(() => {
    setIsLoading(true);
    getAssignmentStatus(Math.abs(parseInt(id || "")));
  }, []);

  if (!studentsData || isLoading) {
    return <Loader />;
  }

  const exportToExcel = () => {
    if (!studentsData || studentsData.length === 0) {
      alert("No student data to export.");
      return;
    }

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Prepare the student data for export
    const studentWorksheetData: any[] = [];

    // Add the headers for the students table using translation
    studentWorksheetData.push([
      t("assignments_group_name"), // Group Name
      t("assignments_student_name"), // Student Name
      t("assignments_books_completed"), // Books Completed
      t("assignments_activities_completed"), // Activities Completed
      t("assignments_last_activity") // Last Activity Date
    ]);

    // Map through the students data and add the rows
    studentsData.forEach((student: any) => {
      // Add the student's data row
      studentWorksheetData.push([
        student.groupName,
        student.name,
        `${student.bookCompletedInAssignment} / ${student.totalBooksInAssigment}`,
        `${student.activitiesCompletedInAssigment} / ${student.totalActivitiesInAssigment}`,
        moment(student.lastActivityDate).format("YYYY-MM-DD")
      ]);
    });

    // After all student data, add an empty row for spacing
    studentWorksheetData.push([]);

    // Now add the activity data for all students in a separate section
    studentsData.forEach((student: any) => {
      // Check if activity data exists for the current student and userId is present
      if (student.userId && activityData && activityData.length > 0) {
        const studentActivities = activityData.filter((activity: any) => activity.userId === student.userId);

        // Only proceed if there are activities for the student
        if (studentActivities && studentActivities.length > 0) {
          // Add a header row for the activity section of this student
          studentWorksheetData.push([`${t("assignments_activity_for")}   ${student.name}`]);

          // Add the headers for the activity table using translation
          studentWorksheetData.push([
            t("assignments_table_cell_date"), // Activity Date
            t("assignments_table_cell_activity"), // Activity
            t("assignments_table_cell_correct_answers"), // Correct Answers
            t("assignments_table_cell_book_name") // Book Name
          ]);

          // Add the activities for the student
          studentActivities.forEach((activity: any) => {
            studentWorksheetData.push([
              moment(activity.activityDate).format("YYYY-MM-DD"),
              activity.activity === 1 ? t("assignments_reading") : t("assignments_quiz"),
              activity.numberOfQuestions && activity.numberOfQuestions > 0
                ? `${activity.correctAnswers} ${t("assignments_out_of")} ${activity.numberOfQuestions}`
                : `${activity.progressInPercent}%`,
              activity.bookName
            ]);
          });

          // Add a blank row after the activity section for spacing
          studentWorksheetData.push([]);
        }
      }
    });

    // Convert the array to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(studentWorksheetData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Assignment");

    // Export the workbook as an Excel file
    XLSX.writeFile(workbook, `Assignment_${id}.xlsx`);
  };

  const StudentsTableBody = () => (
    <div className="studentsTableWrapper sec_body">
      <div className="table-header">
        <span className="select-text mt-4 mb-0 pb-3 fw-bold">{t("assignments_table_header_students")}</span>
      </div>
      <table className="border-separate border-spacing-0.5 pt-2.5">
        <thead>
          {tableStudents.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {tableStudents.getRowModel().rows.map((row) => (
            <tr key={row.id} className={`${row.index % 2 === 0 ? "even" : "odd"}`}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const ActivityTableBody = () => (
    <div className="activityTableWrapper sec_body" ref={acivitySectionRef}>
      <div className="table-header">
        <span className="select-text mt-4 mb-0 pb-3 fw-bold">{t("assignments_table_header_activity")}</span>
      </div>
      <table className="border-separate border-spacing-0.5 pt-2.5">
        <thead>
          {tableActivity.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {tableActivity.getRowModel().rows.map((row) => (
            <tr key={row.id} className={`${row.index % 2 === 0 ? "even" : "odd"}`}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const StudentsNoResults = () => (
    <div className="activityTableWrapper sec_body">
      <div className="table-header">
        <span className="select-text mt-4 mb-0 pb-3 fw-bold">{t("assignments_table_header_students")}</span>
      </div>
      <span className="select-text mt-4 mb-0 pb-3 fw-regular">{t("assignments_no_students_assigned")}</span>
    </div>
  );

  const ActivityNoResults = () => (
    <div className="activityTableWrapper sec_body">
      <div className="table-header">
        <span className="select-text mt-4 mb-0 pb-3 fw-bold">{t("assignments_table_header_activity")}</span>
      </div>
      <span className="select-text mt-4 mb-0 pb-3 fw-regular">{t("assignments_no_activities")}</span>
    </div>
  );

  return (
    <Layout>
      <StyledViewAssignment>
        <div className="assignments-page">
          <div className="main_breadcrumb">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/assignments">{t("menu_item_assignments")}</Link>
                </li>
                <li className="breadcrumb-item">
                  {t("assignment_name")} {id}
                </li>
              </ol>
            </nav>
          </div>
          <div className="buttonContainer">
            <button type="button" className="btn btn-error btn-edit" onClick={exportToExcel}>
              {t("assignments_button_export")}
            </button>
          </div>
          {studentsData?.length ? <StudentsTableBody /> : studentsData ? <StudentsNoResults /> : <></>}{" "}
          {activityData?.length ? <ActivityTableBody /> : activityData ? <ActivityNoResults /> : <></>}{" "}
        </div>
      </StyledViewAssignment>
    </Layout>
  );
};

export default ViewAssignment;
